// Models and constants for papyrus text

import DefaultText from "@/components/PapyrusTextComponents/DefaultText.vue";
import AnnotatedText from "@/components/PapyrusTextComponents/AnnotatedText.vue";
import { type AnnotatedTextProps } from "@/models/annotation";
export type DefaultTextProps = {
  content: string;
};

export type TextSegment = {
  component: typeof DefaultText | typeof AnnotatedText;
  props: DefaultTextProps | AnnotatedTextProps;
};
export type DocumentDivision = { startRow: number; label: string };
export type ParserResponse = {
  rowLabels: number[];
  documentDivisions: DocumentDivision[];
  rows: TextSegment[][];
};
export type TextType = "Translation" | "Original";

// CSS class names
export const ANNOTATION_CLASS_NAME = "archeion-annotation"; // annotations are stored as HTML span tags
export const ANNOTATION_CONTENT_IDENTIFIER = "data-annotation-id"; // the actual content of an annotation is stored in Strapi
export const ANNOTATION_INTERNAL_IDENTIFIER = "data-internal-id"; // internal, unique identifier
export const HANDSHIFT_CLASS_NAME = "archeion-handshift"; // handshifts are stored as HTML span tags
export const LINEBREAK_CLASS_NAME = "archeion-linebreak"; // linebreaks are stored as HTML span tags
export const DOCUMENTDIVISION_CLASS_NAME = "archeion-documentdivision"; // linebreaks are stored as HTML span tags

// TipTap extension names
export const ANNOTATION_EXTENSION_NAME = "annotation";
export const LINEBREAK_EXTENSION_NAME = `lineBreak`;
export const HANDSHIFT_EXTENSION_NAME = "handshift";
export const ANNOTATION_MARK_NAME = "annotationMark";
export const LINEBREAK_MARK_NAME = `lineBreakMark`;
export const HANDSHIFT_MARK_NAME = "handshiftMark";
export const DOCUMENTDIVISION_EXTENSION_NAME = "documentDivision";
