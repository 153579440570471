<script setup lang="ts">
import { ref } from "vue";

import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";
import { useAdminStore } from "@/stores/useAdminStore";
import { eventBus } from "@/services/EventBus";
import DialogWindowLayout from "./DialogWindowLayout.vue";

const adminStore = useAdminStore();
const lineNumberContent = ref(1);

type DocumentDivisionName =
  | "Verso"
  | "Recto"
  | "Interior"
  | "Exterior"
  | "Column"
  | "Fragment";
type documentDivsionItem = {
  name: DocumentDivisionName;
};
const documentDivisions = ref<documentDivsionItem[]>([
  { name: "Verso" },
  { name: "Recto" },
  { name: "Interior" },
  { name: "Exterior" },
  { name: "Column" }, // Column 1, Column 2, ..
  { name: "Fragment" }, // Fragment A, Fragment B, ..
]);
const selectedDocDivision = ref<documentDivsionItem>();
const selectedDocDivisionName = ref("");
const documentDivisionColumnNumber = ref(1);
const documentDivisionFragmentLetter = ref("");

const onChangeDocumentDivision = () => {
  selectedDocDivisionName.value = selectedDocDivision.value?.name || "";
};

const hasDocumentDivisionField = (): boolean => {
  return adminStore.data.selectedTextType !== "Translation";
};

const getEditorContent = (
  lineNumberContent: number,
  documentDivisionName: string,
  documentDivisionColumnNumber: number,
) => {
  documentDivisionName =
    documentDivisionName === "Column"
      ? `${documentDivisionName} ${documentDivisionColumnNumber}`
      : documentDivisionName;

  documentDivisionName =
    documentDivisionName === "Fragment"
      ? `${documentDivisionName} ${documentDivisionFragmentLetter.value}`
      : documentDivisionName;

  const content = documentDivisionName
    ? `${documentDivisionName} | ${lineNumberContent}`
    : lineNumberContent;
  return content;
};

const onSave = async () => {
  if (!lineNumberContent.value || lineNumberContent.value === 0) {
    adminStore.data.message = {
      type: "error",
      content:
        "Line number field is required. Must be a number greater than 0.",
    };
    return false;
  } else if (
    selectedDocDivisionName.value === "Column" &&
    !documentDivisionColumnNumber.value
  ) {
    adminStore.data.message = {
      type: "error",
      content:
        "Column number field is required. Must be a number greater than 0.",
    };
    return false;
  } else {
    const content = getEditorContent(
      lineNumberContent.value,
      selectedDocDivisionName.value,
      documentDivisionColumnNumber.value,
    );
    eventBus.emit("insertLineBreak", {
      lineBreakContent: String(content),
    });
    onCancel();
  }
};

const onCancel = () => {
  adminStore.closeDialog();
};
</script>
<template>
  <DialogWindowLayout>
    <div class="modal-window-input-field modal-window-input-field-linenumber">
      <div class="modal-window-input-field-label">Line number *</div>
      <InputNumber
        :min="0"
        :max="1000"
        :useGrouping="false"
        v-model="lineNumberContent"
        id="linebreak-content"
        required="true" />

      <div
        v-if="hasDocumentDivisionField()"
        class="document-division-container">
        <div style="margin-top: 24px" class="modal-window-input-field-label">
          Document division (optional)
        </div>

        <Dropdown
          v-model="selectedDocDivision"
          :options="documentDivisions"
          @change="onChangeDocumentDivision"
          optionLabel="name"
          placeholder=" Select document division"
          id="document-division-content" />
        <div v-if="selectedDocDivision?.name === 'Column'">
          <div style="margin-top: 12px" class="modal-window-input-field-label">
            Column number *
          </div>
          <InputNumber
            v-if="selectedDocDivision?.name === 'Column'"
            :min="0"
            :max="1000"
            :useGrouping="false"
            v-model="documentDivisionColumnNumber"
            id="column-number-content"
            required="true" />
        </div>

        <div v-if="selectedDocDivision?.name === 'Fragment'">
          <div style="margin-top: 12px" class="modal-window-input-field-label">
            Column number *
          </div>
          <InputText
            v-if="selectedDocDivision?.name === 'Fragment'"
            maxlength="1"
            placeholder="Enter letter (A, B, C, ..)"
            :useGrouping="false"
            v-model="documentDivisionFragmentLetter"
            id="fragment-letter-content"
            required="true" />
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        severity="info"
        @click="onCancel"></Button>
      <Button
        label="OK"
        severity="success"
        icon="pi pi-check"
        @click="onSave"></Button>
    </template>
  </DialogWindowLayout>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
.archeion-colum-number-field-input {
  width: 100px;
}
</style>
