<script setup lang="ts">
import type { Papyrus } from "@/models/strapi";
import { computed } from "vue";

import { ROUTES } from "@/models/routes";
import PapyrusSelector from "@/components/PapyrusSelector.vue";
import { useAppStore } from "@/stores/useAppStore";

const props = defineProps<{
  papyri: Papyrus[] | [];
  papyrus: Papyrus | undefined;
}>();

const appStore = useAppStore();

const papyriSorted = computed(() => {
  const sorted = props.papyri;
  return sorted.sort((a, b) => a.name.localeCompare(b.name));
});
const selectedIndex = computed(() => {
  return papyriSorted.value.findIndex((item) => {
    return item.id === props.papyrus?.id;
  });
});

const findIndexById = (papyriSorted: Papyrus[], id: number) => {
  return papyriSorted.findIndex((item: Papyrus) => {
    return item.id === id;
  });
};
const onPrev = () => {
  const index = findIndexById(papyriSorted.value, props.papyrus?.id ?? -1);
  const prevIndex = index > 0 ? index - 1 : papyriSorted.value.length - 1;
  window.location.href = `${ROUTES.PAPYRUS.path}/${papyriSorted.value[prevIndex].id}`;
  // @todo: fix this (bug: wrong annotation shown occasionally) without page reload
  // navigateTo(`${ROUTES.PAPYRUS.path}/${papyriSorted.value[prevIndex].id}`);
};

const onNext = () => {
  const index = findIndexById(papyriSorted.value, props.papyrus?.id ?? -1);
  const nextIndex = index < papyriSorted.value.length - 1 ? index + 1 : 0;
  window.location.href = `${ROUTES.PAPYRUS.path}/${papyriSorted.value[nextIndex].id}`;
  // @todo: fix this
  // navigateTo(`${ROUTES.PAPYRUS.path}/${papyriSorted.value[nextIndex].id}`);
};

const onSelectPapyrus = (papyrusId: number) => {
  window.location.href = `${ROUTES.PAPYRUS.path}/${papyrusId}`;
  // @todo: fix this
  // navigateTo(`${ROUTES.PAPYRUS.path}/${papyrusId}`);
};
</script>

<template>
  <div class="papyrus-navigation-bar">
    <PapyrusSelector
      :selectedPapyrusId="appStore.data.selectedPapyrusId"
      :onSelect="onSelectPapyrus"
      :optionsData="papyri"
      :isWide="true" />
    <div class="papyrus-navigation-pager">
      <a
        @click="onPrev()"
        class="papyrus-navigation-pager-link papyrus-navigation-pager-left">
        <span @click="onPrev()" class="papyrus-navigation-pager-left-text"
          >Previous date</span
        >
      </a>

      <div class="papyrus-navigation-counter">
        {{ (selectedIndex + 1).toString().padStart(2, "0") }} of
        {{ props.papyri?.length }}
      </div>
      <a
        @click="onNext()"
        class="papyrus-navigation-pager-link papyrus-navigation-pager-right">
        <span @click="onNext()" class="papyrus-navigation-pager-right-text"
          >Next date</span
        >
      </a>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.papyrus-navigation-bar {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: $phone-breakpoint) {
  .papyrus-navigation-bar {
    flex-direction: column;
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 12px;
    margin-top: 0px;
  }
}

.papyrus-header-text {
  @include large-bold;
  padding: 32px 0 24px 0;
  align-self: flex-start;
}

.papyrus-navigation-pager {
  display: flex;
  align-items: center;
  margin-right: -6px;
}

.papyrus-navigation-pager-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  user-select: none;
}

.papyrus-navigation-pager-left::after {
  content: url("@/assets/images/icon_chevron_left.svg");
  display: inline-block;
  margin-top: 4px;
}

.papyrus-navigation-pager-left:hover {
  .papyrus-navigation-pager-left-text {
    cursor: pointer;
    color: $archeion-red;
  }
}
.papyrus-navigation-pager-left:hover::after {
  filter: brightness(0.8);
  cursor: pointer;
}

.papyrus-navigation-pager-right::before {
  content: url("@/assets/images/icon_chevron_left.svg");
  transform: rotate(180deg);
  display: inline-block;
  margin-top: -8px;
}
.papyrus-navigation-pager-right:hover {
  .papyrus-navigation-pager-right-text {
    cursor: pointer;
    color: $archeion-red;
  }
}
.papyrus-navigation-pager-right:hover::before {
  filter: brightness(0.8);
  cursor: pointer;
}

.papyrus-navigation-counter {
  @include small-bold;
  // avoid jumps on serif font
  width: 72px;
  text-align: center;
}

.papyrus-navigation-pager-right-text {
  @include small-normal;
  display: none;
}
.papyrus-navigation-pager-left-text {
  @include small-normal;
  display: none;
}
</style>
