<script setup lang="ts">
// same but with internal data
import { onMounted, ref } from "vue";

import { useApiStore } from "@/stores/useApiStore";
import PageLayout from "@/components/PageLayout.vue";
import ProgressSpinner from "@/components/LoadingState/ProgressSpinner.vue";
import { loadExternalScript, loadExternalCSS } from "@/services/Helpers";
const apiStore = useApiStore();
const tlIsLoading = ref(true);

interface TLWindow extends Window {
  TL: any;
}

declare const window: TLWindow;

onMounted(() => {
  const run = async () => {
    await loadExternalScript(
      window.location.origin + "/knightLabTimeline/timeline.js",
    );
    await loadExternalCSS(
      window.location.origin + "/knightLabTimeline/timeline.css",
    );
    await loadExternalCSS(
      window.location.origin + "/knightLabTimeline/font.default.css",
    );
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const data = await parseData();

    new window.TL.Timeline("timeline-embed", data, {
      // https://timeline.knightlab.com/docs/options.html
      default_bg_color: { r: 255, g: 255, b: 255 },
      timenav_position: "top",
      script_path: window.location.origin + "/knightLabTimeline/timeline.js",
    });

    tlIsLoading.value = false;
  };
  run();
});

const parseData = () => {
  return transformToTimelineJson();
};

const transformToTimelineJson = (): any => {
  const timelineJson: any = {
    title: {
      media: {
        url: "",
        caption: "",
        credit: "",
      },
      text: {
        headline: "Archeion",
        text: "Timeline",
      },
    },
    events: [],
  };
  apiStore.data.papyri.forEach((papyrus) => {
    const event: any = {
      media: {
        url: "",
        caption: "",
        credit: "",
      },
      start_date: {
        year: getCorrectedYear(papyrus.dateFrom.year),
        month: papyrus.dateFrom.month,
        day: papyrus.dateFrom.day,
      },
      end_date: {
        year: getCorrectedYear(papyrus.dateTo.year),
        month: papyrus.dateTo.month,
        day: papyrus.dateTo.day,
      },
      text: {
        headline: papyrus.name,
        text:
          papyrus.title +
          ` <a class="timeline-papyrus-link" target="_self" href="/papyrus/${papyrus.id}">Show papyrus</a>`,
      },
    };
    timelineJson.events.push(event);
  });
  return timelineJson;
};

// Knightlab parses BCE dates differently
const getCorrectedYear = (year: number) => {
  if (year < 0) {
    return year - 1;
  }
  return year;
};
</script>

<template>
  <PageLayout :isContentPage="true">
    <template #default>
      <ProgressSpinner v-if="tlIsLoading" />

      <div id="timeline-embed" style="width: 100%; height: 600px"></div>
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.about-archeion-header {
  padding-bottom: 36px;
}
.timeline-papyrus-link {
  display: block;
  margin-top: 24px;
  color: $archeion-red !important;
}
.tl-timeline,
.tl-headline,
.tl-text-content p,
.tl-headline-date,
.tl-slidenav-title,
.tl-headline-date {
  font-family: Inter !important;
}
.tl-attribution {
  display: none;
}
.tl-slidenav-icon {
  color: $archeion-red !important;
}
.tl-text-content-container {
  .tl-headline {
    font-size: 24px !important;
    text-transform: none !important;
  }
}
.tl-timeline .tl-slidenav-next,
.tl-timeline .tl-slidenav-previous {
  text-transform: none !important;
}
.tl-my-error {
  color: $archeion-red !important;
}
</style>
