<script setup lang="ts">
import { ref, watch } from "vue";
import { useToast } from "primevue/usetoast";
import Divider from "primevue/divider";
import { useAppStore } from "@/stores/useAppStore";

import PapyrusTextModalPager from "@/components/PapyrusTextModalPager.vue";
import { ROUTES } from "@/models/routes";

import { TOAST_TIMEOUT } from "@/services/Helpers";

const appStore = useAppStore();

const toast = useToast();
const modalRef = ref<HTMLElement | null>(null);

watch(
  () => appStore.data.papyrusText.showModal,
  (newValue) => {
    if (newValue) {
      addClickListener();
    } else {
      removeClickListener();
    }
  },
);

const handleClickOutside = (event: MouseEvent) => {
  if (modalRef.value && !modalRef.value.contains(event.target as Node)) {
    appStore.closePapyrusModal();
  }
};
const addClickListener = () => {
  window.addEventListener("click", handleClickOutside);
};
const removeClickListener = () => {
  window.removeEventListener("click", handleClickOutside);
};

const onCopyAnnotationURL = () => {
  const url = `${window.location.origin}${ROUTES.PAPYRUS.path}/${appStore.data.selectedPapyrusId}?annotationId=${appStore.data.papyrusText.selectedAnnotationId}`;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      toast.add({
        severity: "success",
        summary: `Success`,
        detail: `Annotation URL copied to clipboard.`,
        life: TOAST_TIMEOUT,
      });
    })
    .catch((err) => {
      console.error("Failed to copy text to clipboard: ", err);
    });
};
</script>

<template>
  <transition name="slide-up">
    <div
      class="modal-bottom-up"
      v-if="appStore.data.papyrusText.showModal"
      ref="modalRef">
      <div class="modal-bottom-up-header">
        <div
          :class="`modal-bottom-up-header-text--${appStore.data.papyrusText.annotationType}`">
          {{
            appStore.data.papyrusText.annotationType === "Original"
              ? "Apparatus"
              : "Glossary"
          }}
        </div>
        <div class="papyrus-modal-navigation-pager-container">
          <PapyrusTextModalPager />
        </div>

        <div
          class="modal-bottom-up-close"
          @click="appStore.closePapyrusModal()">
          &times;
        </div>
      </div>
      <Divider class="modal-bottom-divider" />
      <div class="modal-bottom-up-content">
        <div
          class="modal-bottom-up-content-text"
          :class="`modal-bottom-up-content-text--${appStore.data.papyrusText.annotationType}`"
          v-html="appStore.data.papyrusText.modalContent"></div>

        <div
          v-if="appStore.data.papyrusText.annotationType === 'Translation'"
          class="modal-bottom-up-content-citation">
          <a @click.prevent="onCopyAnnotationURL" href="#"
            >Click to copy annotation URL</a
          >
        </div>
      </div>
    </div></transition
  >
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.modal-bottom-up {
  position: fixed;
  z-index: 10;
  left: 20px;
  bottom: 16px;
  overflow: hidden;
  color: $text-color;
  background-color: $archeion-white;
  border: 1px solid $archeion-red;
  border-radius: 16px;
  padding: 12px 64px;
  height: calc(33vh);
  width: calc(100% - 40px);
  max-width: calc($max-width - 24px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
@media (min-width: $max-width) {
  .modal-bottom-up {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: $phone-breakpoint) {
  .modal-bottom-up {
    padding: 24px;
    padding-top: 12px;
  }
}
.modal-bottom-up-close {
  position: absolute;
  top: 24px;
  right: 24px;
  color: #aaa;
  font-size: 28px;
  font-weight: 700;
}

.modal-bottom-up-close:hover,
.modal-bottom-up-close:focus {
  color: #000;
  cursor: pointer;
}

.modal-bottom-up-header {
  font: 700 16px/22px Inter !important;
  letter-spacing: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.modal-bottom-up-header-text--Original {
  width: 64px;
}
.modal-bottom-up-header-text--Translation {
  width: 64px;
}

.modal-bottom-up-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; //center;
  font: 300 16px/22px Inter !important;
  letter-spacing: 0px;
}

.modal-bottom-up-content-text {
  width: 100%;
  height: calc(33vh - 128px);
  overflow-y: auto;
}
.modal-bottom-up-content-text p {
  display: inline;
}

.modal-bottom-up-content-citation {
  margin-top: 12px;
  align-self: flex-end;
}
.modal-bottom-divider {
  // Safari bug
  padding: 0 0 6px 0 !important;
}

// Animation
.slide-up-enter-active,
.slide-up-leave-active {
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
}
.slide-up-enter-from,
.slide-up-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
.slide-up-enter-to,
.slide-up-leave-from {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: $max-width) {
  .slide-up-enter-active,
  .slide-up-leave-active {
    transition:
      opacity 0.5s ease,
      transform 0.5s ease;
  }
  .slide-up-enter-from,
  .slide-up-leave-to {
    opacity: 0;
    transform: translateY(100%) translateX(-50%);
    left: 50%;
  }
  .slide-up-enter-to,
  .slide-up-leave-from {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
    left: 50%;
  }
}
.papyrus-modal-navigation-pager-container {
  margin-left: auto;
  margin-right: auto;
}
</style>
