import { useAdminStore } from "@/stores/useAdminStore";
import { AdminApi } from "@/services/AdminApi";
import { eventBus } from "@/services/EventBus";
import type { AnnotationContentStrapi } from "@/models/strapi";
import type { AnnotationContent } from "@/models/annotation";

export const createAnnotation = async (payload: AnnotationContent) => {
  const adminStore = useAdminStore();
  const result = await AdminApi.createAnnotation({
    Content: payload.content,
    Title: payload.title,
    Description: payload.description,
    Type: payload.type,
  } as AnnotationContentStrapi);
  adminStore.addAnnotation(Number(result.data.id), {
    content: result.data.attributes.Content,
    title: result.data.attributes.Title,
    description: result.data.attributes.Description,
    type: result.data.attributes.Type,
  } as AnnotationContent);
  adminStore.data.message = { type: "success", content: "Annotation created" }; // applies tiptap annotations
  eventBus.emit("createAnnotation", { annotationId: result.data.id });
};

export const updateAnnotation = async (
  papyrusId: number,
  payload: AnnotationContent,
) => {
  const adminStore = useAdminStore();
  const result = await AdminApi.updateAnnotation(papyrusId, {
    Content: payload.content,
    Title: payload.title,
    Description: payload.description,
    Type: payload.type,
  } as AnnotationContentStrapi);
  adminStore.updateAnnotation(result.data.id, {
    content: result.data.attributes.Content,
    title: result.data.attributes.Title,
    description: result.data.attributes.Description,
    type: result.data.attributes.Type,
  } as AnnotationContent);
  adminStore.data.message = { type: "success", content: "Annotation updated" };
};
