<script setup lang="ts">
import { ref, computed, watch, type ComputedRef } from "vue";
import { useApiStore } from "@/stores/useApiStore";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import { ROUTES } from "@/models/routes";
import type { BookEdition } from "@/models/strapi";
import { useAppStore } from "@/stores/useAppStore";

type Edition = BookEdition & {
  id: number;
};

const apiStore = useApiStore();
const appStore = useAppStore();
const series = ref("");
const volume = ref("");
const documentNumber = ref("");

const papyriData: ComputedRef<Edition[]> = computed(() => {
  const papyri = apiStore.data.papyri;
  const result: Edition[] = [];

  papyri.forEach((papyrus) => {
    papyrus.editions.forEach((edition) => {
      const entry: Edition = {
        series: edition.series || "",
        volume: edition.volume || "",
        documentNo: edition.documentNo || "",
        id: papyrus.id,
      };
      if (entry.series) {
        result.push(entry);
      }
    });
  });

  return result;
});

const seriesOptions = computed(() => {
  const seriesMap: Map<string, number> = new Map();

  papyriData.value.forEach((item) => {
    if (seriesMap.has(item.series)) {
      seriesMap.set(item.series, seriesMap.get(item.series)! + 1);
    } else {
      seriesMap.set(item.series, 1);
    }
  });

  const options = Array.from(seriesMap.entries()).map(([series, count]) => ({
    value: series,
    label: `${series}  (${count})`,
  }));

  return options.sort((a, b) => a.value.localeCompare(b.value));
});

const volumeOptions = computed(() => {
  if (!series.value) return [];
  const volumeMap: Map<string, number> = new Map();

  papyriData.value
    .filter((item) => item.series === series.value)
    .forEach((item) => {
      if (volumeMap.has(item.volume)) {
        volumeMap.set(item.volume, volumeMap.get(item.volume)! + 1);
      } else {
        volumeMap.set(item.volume, 1);
      }
    });

  const options = Array.from(volumeMap.entries()).map(([volume, count]) => ({
    label: `${volume}  (${count})`,
    value: volume,
  }));

  return options.sort((a, b) => a.label.localeCompare(b.label));
});

const documentNumberOptions = computed(() => {
  const documentNumberSet: Set<string> = new Set();

  if (!isDocumentNumberDisabled()) {
    papyriData.value.forEach((item) => {
      if (item.series === series.value) {
        if (
          (hasVolumeEntries(series.value) && item.volume === volume.value) ||
          !hasVolumeEntries(series.value)
        ) {
          documentNumberSet.add(String(item.documentNo));
        }
      }
    });
  }
  const resultArray: string[] = Array.from(documentNumberSet);
  return resultArray.sort((a, b) => {
    const numA = parseFloat(a);
    const numB = parseFloat(b);
    // sort numerical if both entries are numbers
    if (!isNaN(numA) && !isNaN(numB)) {
      return numA - numB;
    }
    // sort numbers before strings
    if (!isNaN(numA)) return -1;
    if (!isNaN(numB)) return 1;
    // sort alphabetical if both entries are strings
    return a.localeCompare(b);
  });
});

const hasVolumeEntries = (series: string) => {
  const foundEntries = papyriData.value.some(
    (item) => item.series === series && item.volume,
  );
  return foundEntries;
};

watch(series, () => {
  volume.value = "";
  documentNumber.value = "";
});

watch(volume, () => {
  documentNumber.value = "";
});

const isSubmitDisabled = (): boolean => {
  const isDisabled =
    !series.value ||
    (hasVolumeEntries(series.value) && !volume.value) ||
    !documentNumber.value;

  if (!isDisabled) {
    appStore.data.selectedPapyrusId = getSelectedPapyrusId();
  }

  return isDisabled;
};

const getSelectedPapyrusId = () => {
  const normalizedVolume = volume.value || "";
  const found = papyriData.value.filter((item) => {
    if (
      item.series === series.value &&
      item.volume === normalizedVolume &&
      item.documentNo === String(documentNumber.value)
    ) {
      return item;
    }
  });
  return found.length === 1 ? found[0].id : 0;
};

const onSubmit = () => {
  if (appStore.data.selectedPapyrusId > 0) {
    location.href = `${ROUTES.PAPYRUS.path}/${appStore.data.selectedPapyrusId}`;
    // TODO: fix store problem
    //router.push(`${ROUTES.PAPYRUS.path}/${appStore.data.selectedPapyrusId}`);
  }
};

const isVolumeDisabled = () => {
  return series.value === "" || !hasVolumeEntries(series.value);
};
const isDocumentNumberDisabled = () => {
  return (
    series.value === "" ||
    (hasVolumeEntries(series.value) && volume.value === "")
  );
};
</script>

<template>
  <div class="index-search-container">
    <p>Find papyri by book edition.</p>
    <div class="index-search-selects">
      <div class="index-search-select">
        <Dropdown
          :pt="{
            root: 'archeion-dropdown',
            item: 'archeion-dropdown-item',
            itemLabel: 'archeion-dropdown-label',
            placeholder: 'archeion-dropdown-placeholder',
          }"
          v-model="series"
          :options="seriesOptions"
          optionLabel="label"
          optionValue="value"
          placeholder="Select series" />
      </div>
      <div class="index-search-select">
        <Dropdown
          :pt="{
            root: 'archeion-dropdown',
            item: 'archeion-dropdown-item',
            itemLabel: 'archeion-dropdown-label',
          }"
          v-model="volume"
          :disabled="isVolumeDisabled()"
          :options="volumeOptions"
          optionLabel="label"
          optionValue="value"
          placeholder="Select volume" />
      </div>
      <div class="index-search-select">
        <Dropdown
          :pt="{
            root: 'archeion-dropdown',
            item: 'archeion-dropdown-item',
            itemLabel: 'archeion-dropdown-label',
          }"
          v-model="documentNumber"
          :disabled="isDocumentNumberDisabled()"
          :options="documentNumberOptions"
          placeholder="Select document no." />
      </div>
      <div class="index-search-select-submit">
        <Button
          :pt="{ root: 'archeion-rounded-button' }"
          @click.prevent="onSubmit"
          :disabled="isSubmitDisabled()"
          >Open Papyrus
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.index-search-container {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.index-search-selects {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  .p-dropdown-label {
    margin-top: 6px;
  }
}

.index-search-select-submit {
  .archeion-rounded-button {
    @include archeion-rounded-button;
  }
  margin-top: 12px;
}
</style>
