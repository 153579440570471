<script setup lang="ts">
import { computed, ref, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { FilterMatchMode } from "primevue/api";
import type { DataTableRowSelectEvent } from "primevue/datatable/DataTable.d.ts";

import SearchField from "@/components/SearchField.vue";
import { ROUTES } from "@/models/routes";
import { useApiStore } from "@/stores/useApiStore";
import { getDateStr } from "@/services/Helpers";
import type { Papyrus } from "@/models/strapi";

const apiStore = useApiStore();
const router = useRouter();
const route = useRoute();

const searchQuery = ref("");
const filters = ref({
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
});
watch(searchQuery, (newValue) => {
  filters.value.global.value = newValue;
  router.replace({ query: { q: newValue || "" } });
});
onMounted(() => {
  if (route.query.q) {
    searchQuery.value = route.query.q as string;
  }
});

// needed to avoid search hits with "<"
const papyriDataPlainText = computed(() => {
  const papyri = apiStore.data.papyri;
  papyri.forEach((p: Papyrus) => {
    p.title = p.title.replace(/<[^>]*>/g, "");
    p.content = p.content.replace(/<[^>]*>/g, "");
    p.form = p.form.replace(/<[^>]*>/g, "");
  });
  return papyri;
});

const onRowSelect = (event: DataTableRowSelectEvent) => {
  console.log("onRowSelect", event);
  window.location.href = `${ROUTES.PAPYRUS.path}/${event.data.id}`;
  // TODO: fix store problem
  // router.push(`${ROUTES.PAPYRUS.path}/${event.data.id}`);
};
const highlightMatch = (text: string) => {
  if (!searchQuery.value) return text;
  const regex = new RegExp(searchQuery.value, "gi");
  return text.replace(
    regex,
    (match) => `<span class="papyri-list-highlight">${match}</span>`,
  );
};
</script>

<template>
  <div class="home-papyri-list">
    <DataTable
      :pt="{ root: 'home-papyri-datatable' }"
      :value="papyriDataPlainText"
      v-model:filters="filters"
      :globalFilterFields="[
        'keywords_flattened',
        'title',
        'content',
        'form',
        'name',
      ]"
      selectionMode="single"
      dataKey="id"
      @rowSelect="onRowSelect"
      sortField="name"
      :sortOrder="1">
      <template #header>
        <div class="home-papyri-list-search-container">
          <SearchField
            placeholder="Start typing to filter"
            name="home-papyri-search"
            :inputValue="searchQuery"
            @updateValue="searchQuery = $event" />
        </div>
      </template>
      <template #empty> No papyri found. </template>
      <Column sortable field="name" header="Editions"
        ><template #body="slotProps">
          <span v-html="highlightMatch(slotProps.data.name)"></span></template
      ></Column>
      <Column sortable field="title" header="Title">
        <template #body="slotProps">
          <span v-html="highlightMatch(slotProps.data.title)"></span
        ></template>
      </Column>
      <Column
        sortable
        field="dateFrom"
        header="Date"
        sortField="dateFromInteger"
        ><template #body="slotProps">
          {{ getDateStr(slotProps.data.dateFrom, slotProps.data.dateTo) }}
        </template></Column
      >

      <Column sortable field="content" header="Content"
        ><template #body="slotProps">
          <span
            v-html="highlightMatch(slotProps.data.content)"></span></template
      ></Column>

      <Column sortable field="form" header="Form"
        ><template #body="slotProps">
          <span v-html="highlightMatch(slotProps.data.form)"></span></template
      ></Column>
      <Column sortable field="keywords_flattened" header="Keywords"
        ><template #body="slotProps">
          <span
            v-html="
              highlightMatch(slotProps.data.keywords_flattened)
            "></span></template
      ></Column>
    </DataTable>
  </div>
</template>
<style lang="scss">
@import "@/assets/variables.scss";

.home-papyri-list {
  margin-top: 12px;
  .home-papyri-datatable {
    @include small-normal;
  }
}
.home-papyri-list-search-container {
  margin-left: -16px;
}

.papyri-list-highlight {
  background-color: $search-highlight-color;
}
</style>
