<script setup lang="ts">
import { ref, watch, computed, onMounted } from "vue";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { FilterMatchMode } from "primevue/api";

import { useAdminStore } from "@/stores/useAdminStore";
import type { Annotations, AnnotationContent } from "@/models/annotation";
import SearchField from "@/components/SearchField.vue";

const adminStore = useAdminStore();
const searchQuery = ref("");
const isLoading = ref(true);
const filters = ref({
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
});
const selectedAnnotation = ref();

watch(searchQuery, (newValue) => {
  filters.value.global.value = newValue;
});
watch(selectedAnnotation, () => {
  adminStore.data.reassignedAnnotationId = selectedAnnotation.value.id;
});

onMounted(async () => {
  adminStore.data.reassignedAnnotationId = 0;
  await onRefresh();
});

const onRefresh = async () => {
  isLoading.value = true;
  await adminStore.fetchAnnotations();
  await new Promise((resolve) => setTimeout(resolve, 500));
  isLoading.value = false;
};
const filteredData = computed(() => {
  const recordSet: Annotations = adminStore.data.annotations;
  const mapped = Object.entries(recordSet)
    .map(([id, annotation]): { id: string } & AnnotationContent => ({
      id,
      ...annotation,
      type: annotation.type ?? "",
      title: annotation.title ?? "",
      description: annotation.description ?? "",
    }))
    .filter(({ type }) => type === "Translation");
  return mapped;
});
</script>
<template>
  <span class="glossary-add-existing-list">
    <DataTable
      paginator
      :rows="50"
      showGridlines
      :value="filteredData"
      v-model:selection="selectedAnnotation"
      v-model:filters="filters"
      :globalFilterFields="['title', 'content', 'description']"
      dataKey="id"
      :sortOrder="1"
      :class="`p-datatable-sm p-datatable-striped`">
      <template #header>
        <SearchField
          name="editor-annotation-list-search"
          placeholder="Start typing to filter"
          :inputValue="searchQuery"
          @updateValue="searchQuery = $event" />
      </template>
      <template #empty> No annotations found. </template>
      <Column
        class="editor-annotation-list-column"
        selectionMode="single"
        headerStyle="width: 3rem"></Column>
      <Column
        class="editor-annotation-list-column"
        field="content"
        header="Content">
        <template #body="slotProps">
          <div v-html="slotProps.data.content"></div>
        </template>
      </Column>
      <Column
        class="editor-annotation-list-column"
        field="title"
        header="Title"></Column>
      <Column
        class="editor-annotation-list-column"
        field="description"
        header="Description"></Column> </DataTable
  ></span>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
.glossary-add-existing-list {
  .editor-annotation-list-column {
    @include small-normal;
  }
  .p-column-title {
    @include small-bold;
  }
  .p-paginator-page.p-highlight {
    background-color: $archeion-red;
    color: $archeion-white;
  }
}
</style>
